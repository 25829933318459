@import "palettes";
@import "theme";
@import "mixins";

.search_info {
  margin: 20px 0;
  @include trim-v-margins;
  color: $gray-500;
  text-align: center;
  font-style: italic;
  line-height: $spacing + 0.3;
}
